<template>

    <div>
        <sidebar
            :custom_class="this.$store.state.mcolor"
            :class="[
                this.$store.state.isTransparent,
                this.$store.state.isRTL ? 'fixed-end' : 'fixed-start',
            ]"
            v-if="this.$store.state.showSidenav"
        />

        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg" :style="this.$store.state.isRTL ? 'overflow-x: hidden' : ''">
            <!-- nav -->
            <navbar
                :class="[navClasses]"
                :textWhite="this.$store.state.isAbsolute ? 'text-white opacity-8' : ''"
                :minNav="navbarMinimize" v-if="this.$store.state.showNavbar"
            />

            <router-view />

            <!-- <app-footer v-show="this.$store.state.showFooter" /> -->

            <!-- <configurator
                :toggle="toggleConfigurator"
                :class="[
                    this.$store.state.showConfig ? 'show' : '',
                    this.$store.state.hideConfigButton ? 'd-none' : '',
                ]"
            /> -->

        </main>

        <!-- Preview Photo User -->
        <div
            v-if="this.$store.state.previewImgUrl"
            class="preview-photo"
            :style="{
                top: `${this.$store.state.previewImgTopPosition}px`,
                left: `${this.$store.state.previewImgLeftPosition}px`
            }"
        >
            <img alt="" v-no-image :src="this.$store.state.previewImgUrl">
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { mapMutations } from 'vuex'
import Navbar from '@/layouts/default/partials/Navbar.vue'
import Sidebar from '@/layouts/default/partials/Sidebar.vue'
import AppFooter from '@/layouts/default/partials/Footer.vue'

export default {
    name: 'App',
    components: {
        Navbar,
        Sidebar,
        AppFooter,
        // Configurator,
    },
    methods: {
        ...mapMutations(['toggleConfigurator', 'navbarMinimize']),
    },
    computed: {
        navClasses() {
            return {
                'position-sticky blur shadow-blur mt-4 left-auto top-1 z-index-sticky': this.$store.state.isNavFixed,
                'position-absolute px-4 mx-0 w-100 z-index-2': this.$store.state.isAbsolute,
                'px-0 mx-4 mt-4': !this.$store.state.isAbsolute,
            }
        },
    },
    beforeMount() {
        this.$store.state.isTransparent = 'bg-transparent'
    },
}
</script>

<style lang="scss">
.preview-photo {
    position: absolute;
    width: 220px;
    color: #fff;
    border: 0;
    border-radius: 4px;
    z-index: 99;
    height: auto;
    border: 1px solid #555;
    background: #555;
    display: inline-block;

    img {
        width: 100%;
        height: auto;
        border-radius: 5px;
    }
}
</style>
