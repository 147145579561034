<template>

    <aside
        class="my-3 border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl"
        :class="isRTL ? 'me-3 rotate-caret' : 'ms-3'" id="sidenav-main"
    >
        <div class="sidenav-header">
            <i class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
            <router-link :to="{ name: 'Dashboard' }" class="m-0 navbar-brand" tag="a">
                <img src="~@/assets/img/logo-lg-colored.png" class="navbar-brand-img h-100" alt="main_logo" />
                <!-- <span class="ms-1 font-weight-bold">VenueLista</span> -->
            </router-link>
        </div>

        <hr class="mt-0 horizontal dark" />

        <!--  -->
        <div class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100" id="sidenav-collapse-main">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <sidenav-collapse navText="Dashboard" :to="{ name: 'Dashboard' }">
                        <template #icon>
                            <Dashboard />
                        </template>
                    </sidenav-collapse>
                </li>
                <li class="nav-item">
                    <sidenav-collapse navText="Venues" :to="{ name: 'Venues' }">
                        <template #icon>
                            <office />
                        </template>
                    </sidenav-collapse>
                </li>
                <li class="nav-item">
                    <sidenav-collapse navText="Members" :to="{ name: 'Members' }">
                        <template #icon>
                            <Members />
                        </template>
                    </sidenav-collapse>
                </li>

                <!-- <li class="nav-item">
                    <sidenav-collapse navText="Deals" :to="{ name: 'Deals' }">
                        <template #icon>
                            <settings />
                        </template>
                    </sidenav-collapse>
                </li> -->

                <li class="mt-3 nav-item">
                    <h6 class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6" :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'">Operations</h6>
                </li>
                <li class="nav-item">
                    <sidenav-collapse navText="Reservations" :to="{ name: 'Reservations' }">
                        <template #icon>
                            <customer-support />
                        </template>
                    </sidenav-collapse>
                </li>
                <li class="nav-item">
                    <sidenav-collapse navText="Bookings" :to="{ name: 'Bookings' }">
                        <template #icon>
                            <document />
                        </template>
                    </sidenav-collapse>
                </li>
                <li class="nav-item">
                    <sidenav-collapse navText="Events" :to="{ name: 'Events' }">
                        <template #icon>
                            <Tickets />
                        </template>
                    </sidenav-collapse>
                </li>
                <li class="nav-item">
                    <sidenav-collapse navText="Bottles" :to="{ name: 'Bottles' }">
                        <template #icon>
                            <Tickets />
                        </template>
                    </sidenav-collapse>
                </li>
                <li class="nav-item">
                    <sidenav-collapse navText="Gift Cards" :to="{ name: 'GiftCards' }">
                        <template #icon>
                            <Tickets />
                        </template>
                    </sidenav-collapse>
                </li>
                <li class="nav-item">
                    <sidenav-collapse navText="Reviews" :to="{ name: 'Reviews' }">
                        <template #icon>
                            <Tickets />
                        </template>
                    </sidenav-collapse>
                </li>
                <li class="nav-item">
                    <sidenav-collapse navText="Careers" :to="{ name: 'Careers' }">
                        <template #icon>
                            <spaceship />
                        </template>
                    </sidenav-collapse>
                </li>
                <li class="nav-item">
                    <sidenav-collapse navText="Mailing Lists" :to="{ name: 'MailingLists' }">
                        <template #icon>
                            <spaceship />
                        </template>
                    </sidenav-collapse>
                </li>

                <!-- <li class="mt-3 nav-item">
                    <h6 class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6" :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'">Analytics</h6>
                </li>
                <li class="nav-item">
                    <sidenav-collapse navText="Visitors" :to="{ name: 'Visitors' }">
                        <template #icon>
                            <customer-support />
                        </template>
                    </sidenav-collapse>
                </li>
                <li class="nav-item">
                    <sidenav-collapse navText="Steps Logs" :to="{ name: 'StepsLogs' }">
                        <template #icon>
                            <document />
                        </template>
                    </sidenav-collapse>
                </li> -->

                <!-- <li class="mt-3 nav-item">
                    <h6 class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6" :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'">Settings</h6>
                </li>
                <li class="nav-item">
                    <sidenav-collapse navText="Settings" :to="{ name: 'Settings' }">
                        <template #icon>
                            <customer-support />
                        </template>
                    </sidenav-collapse>
                </li> -->

            </ul>
        </div>

        <!-- <div class="pt-3 mx-3 mt-3 sidenav-footer">
            <sidenav-card
                :class="cardBg"
                textPrimary="Need Help?"
                textSecondary="Please check our docs"
                route="https://www.creative-tim.com/learning-lab/vue/overview/soft-ui-dashboard/"
                label="Documentation"
                icon="ni ni-diamond"
            />
        </div> -->

    </aside>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex'
import SidenavCollapse from '@/components/SidenavCollapse.vue'

/* ICONS */
import Tickets from '@/components/Icon/Tickets.vue'
import Members from '@/components/Icon/Members.vue'
import Dashboard from '@/components/Icon/Dashboard.vue'



import Shop from '@/components/Icon/Shop.vue'
import Box3d from '@/components/Icon/Box3d.vue'
import Office from '@/components/Icon/Office.vue'
import Settings from '@/components/Icon/Settings.vue'
import Document from '@/components/Icon/Document.vue'
import Spaceship from '@/components/Icon/Spaceship.vue'
import CreditCard from '@/components/Icon/CreditCard.vue'
import CustomerSupport from '@/components/Icon/CustomerSupport.vue'

export default {
    name: 'Sidebar',

    components: {
        Members,
        Tickets,
        Dashboard,
        // 
        Shop,
        Box3d,
        Office,
        Settings,
        Document,
        Spaceship,
        CreditCard,
        CustomerSupport,
        SidenavCollapse,
    },

    data() {
        return {}
    },

    props: {
        customClass: {
            type: String,
            default: '',
        },
    },

    computed: {
        ...mapState(['isRTL']),
    },

}
</script>
